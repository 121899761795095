<template>
  <div>
    <v-tabs v-model="activeTab" class="d-flex justify-center mb-5">
      <v-tab id="gps-page-step-0" :disabled="tourActive">{{ $t('gpsMaps.drivers') }}</v-tab>
      <v-tab id="gps-page-step-1" :disabled="tourActive" v-if="!userIsCustomer">{{ $t('gpsMaps.transfers') }}</v-tab>
      <v-tab :id="window.width < 600 ? 'gps-page-step-2' : ''" class="mobile-maps-tab" :disabled="tourActive">{{
        $t('gpsMaps.map')
      }}</v-tab>
    </v-tabs>

    <div class="d-flex align-center px-3">
      <v-checkbox
        v-if="activeTab == 0 && !userIsCustomer"
        v-model="affiliate_checkbox"
        color="success"
        :label="$t('affiliate.affiliate')"></v-checkbox>
      <v-spacer />
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="icon-btn d-flex ms-auto" v-bind="attrs" @click="$emit('loadAllData')" v-on="on">
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tooltips.refresh') }}</span>
      </v-tooltip>
    </div>

    <v-tabs-items v-model="activeTab">
      <v-tab-item key="1">
        <div class="cards pa-3">
          <template v-for="driver in drivers">
            <driver-card
              :key="driver?.id"
              :driver="driver"
              :active="driver?.id == activeDriver?.id ? true : false"
              :clickable="true"
              @handleActiveDriver="handleActiveDriver"
              @loadAllData="$emit('loadAllData')"></driver-card>
          </template>
        </div>
      </v-tab-item>
      <v-tab-item key="2" v-if="!userIsCustomer">
        <div class="cards pa-3" v-if="ongoingTransfers.length || upcomingTransfers.length">
          <!-- Show ongoing transfers -->
          <p class="transfer-status mb-3" v-if="ongoingTransfers.length">
            {{ $t('gpsMaps.ongoing') }}
          </p>
          <template v-for="transfer in ongoingTransfers">
            <transfer-card
              :key="transfer?.id"
              :transfer="transfer"
              :drivers="drivers"
              :vehicles="vehicles"
              :active="transfer?.id == activeTransferId ? true : false"
              @handleActiveTransfer="handleActiveTransfer"
              @loadAllData="$emit('loadAllData')"></transfer-card>
          </template>

          <!-- Show upcoming transfers -->
          <p class="transfer-status mb-3" v-if="upcomingTransfers.length">
            {{ $t('gpsMaps.upcoming') }}
          </p>
          <template v-for="transfer in upcomingTransfers">
            <transfer-card
              :key="transfer?.id"
              :transfer="transfer"
              :drivers="drivers"
              :vehicles="vehicles"
              :active="transfer?.id == activeTransferId ? true : false"
              @handleActiveTransfer="handleActiveTransfer"
              @loadAllData="$emit('loadAllData')"></transfer-card>
          </template>
        </div>
        <div v-else class="pa-3">
          <p class="no-transfers">
            {{ $t('gpsMaps.noTransfers') }}
          </p>
        </div>
      </v-tab-item>
      <v-tab-item key="3">
        <v-col cols="12" class="pa-0 mt-5" style="height: 70vh">
          <google-maps
            :selectedTab="selectedTab"
            :transfers="transfers"
            :drivers="drivers"
            :activeTransferId="activeTransferId"
            :activeDriver="activeDriver"
            :window="window"
            @handleActiveTransfer="handleActiveTransfer"
            @handleActiveDriver="handleActiveDriver"></google-maps>
        </v-col>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TransferCard from '@/components/gps-map/TransferCard';
import DriverCard from '@/components/gps-map/DriverCard';
import GoogleMaps from '@/components/gps-map/GoogleMaps';
import { mapState } from 'vuex';

export default {
  name: 'GoogleMapsTabs',
  props: ['transfers', 'drivers', 'vehicles', 'activeTransferId', 'activeDriver', 'selectedTab', 'userIsCustomer'],
  components: { TransferCard, DriverCard, GoogleMaps },
  data: () => ({
    activeTab: 0,
    window: {
      width: 0,
    },
    affiliate_checkbox: false,
  }),
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    ongoingTransfers() {
      return this.transfers.filter((item) => {
        return item?.active_status == 'ongoing';
      });
    },
    upcomingTransfers() {
      return this.transfers.filter((item) => {
        return item?.active_status == 'upcoming';
      });
    },
  },
  methods: {
    handleActiveTransfer(id) {
      this.$emit('handleActiveTransfer', id); // To know on which transfer we have clicked on
    },
    handleActiveDriver(driver) {
      this.$emit('handleActiveDriver', driver); // To know on which driver we have clicked on
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
  },
  watch: {
    activeTab(val) {
      this.$emit('tabChanged', val); // we need selected tab info in the parent scope in order to show transfers or drivers pins on map
    },
    affiliate_checkbox() {
      this.$emit('loadAllDriversData', this.affiliate_checkbox);
    },
  },
};
</script>

<style scoped>
.transfer-status {
  color: var(--greys-Grey-1, #1b1d20);
  font-family: 'Inter', 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (min-width: 600px) {
  .mobile-maps,
  .mobile-maps-tab {
    display: none;
  }
}
</style>
